












































import { Component, Prop, Vue } from 'vue-property-decorator'
import { GameModule } from '@/store/modules'
import CharacterSwappingModal from './CharacterSwappingModal.vue'

@Component({
  components: {
    CharacterCard: () => import('@/components/Character/CharacterCard.vue'),
    CharacterSwappingModal: () => import( './CharacterSwappingModal.vue'),
  }
})
export default class ProfileCharacters extends Vue {
  @Prop({ type: Boolean, default: false }) userCharacters
  @GameModule.State characters
  @GameModule.State gameAccounts
  @GameModule.Mutation setUserCharacters
  @GameModule.Action getCharacters

  selectedCharacters:unknown[] = []

  getGameAccountOf(accountId: number) {
    return this.gameAccounts.find(e => e.id === accountId)?.username
  }

  async mounted() {
    if (this.userCharacters) {
      const charactersResults = await this.getCharacters({
        data: this.$route.params.id,
        APIType: 'Admin'
      })

      this.setUserCharacters(charactersResults)
      this.selectedCharacters = charactersResults
    } else {
      this.selectedCharacters = this.characters
    }
  }
}
