import { render, staticRenderFns } from "./ProfileCharacters.vue?vue&type=template&id=094140bc&scoped=true&"
import script from "./ProfileCharacters.vue?vue&type=script&lang=ts&"
export * from "./ProfileCharacters.vue?vue&type=script&lang=ts&"
import style0 from "./ProfileCharacters.vue?vue&type=style&index=0&id=094140bc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "094140bc",
  null
  
)

export default component.exports